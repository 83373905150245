import { Employee } from '../../common/types';
import { getOmnipresentTenantId } from '../companies';
import { PERMISSION_MATRICES } from './matrices';
import {
  BasePermissions,
  PermissionsByResource,
  ResourceType,
  RoleType,
} from './types';

export const getAdminPermissionsOnEmployee = <T extends ResourceType>({
  userRoles,
  employee,
  resourceType,
}: {
  userRoles: RoleType[];
  employee: Employee;
  resourceType: T;
}): PermissionsByResource[T] => {
  if (!employee.managerId) {
    const samplePermissions = PERMISSION_MATRICES[resourceType].internal.admin;

    const defaultDeniedPermissions = Object.keys(samplePermissions).reduce(
      (acc, key) => ({
        ...acc,
        [key]: false,
      }),
      {} as PermissionsByResource[T],
    );

    return defaultDeniedPermissions;
  }

  const isInternalEmployee = employee.managerId === getOmnipresentTenantId();
  const employeeType = isInternalEmployee ? 'internal' : 'client';

  const getRolePermissions = () => {
    return userRoles
      .filter((role): role is RoleType => {
        return (
          !['employee', 'manager', 'lsp', 'contractor'].includes(role) &&
          PERMISSION_MATRICES[resourceType][employeeType][role] !== undefined
        );
      })
      .map((role) => PERMISSION_MATRICES[resourceType][employeeType][role]);
  };

  const combinePermissions = (
    permissions: Array<BasePermissions & Record<string, boolean>>,
  ) => {
    const allKeys = new Set(permissions.flatMap((p) => Object.keys(p)));
    return Array.from(allKeys).reduce(
      (combined, key) => ({
        ...combined,
        [key]: permissions.some((p) => p[key]),
      }),
      {} as PermissionsByResource[T],
    );
  };

  return combinePermissions(getRolePermissions());
};
