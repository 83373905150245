import { Employee } from '../../common/types';
import { getAdminPermissionsOnEmployee } from './getAdminPermissionsOnEmployee';
import { PERMISSION_MATRICES } from './matrices';
import { AllResourcePermissions, ResourceType, RoleType } from './types';

export const getAllPermissionsOnEmployee = ({
  userRoles,
  employee,
}: {
  userRoles: RoleType[];
  employee: Employee;
}): AllResourcePermissions => {
  if (!employee.managerId) {
    return Object.keys(PERMISSION_MATRICES).reduce((acc, resourceType) => {
      const samplePermissions =
        PERMISSION_MATRICES[resourceType as ResourceType].internal.admin;
      const deniedPermissions = Object.keys(samplePermissions).reduce(
        (permAcc, key) => ({
          ...permAcc,
          [key]: false,
        }),
        {},
      );

      return {
        ...acc,
        [resourceType]: deniedPermissions,
      };
    }, {} as AllResourcePermissions);
  }

  return Object.keys(PERMISSION_MATRICES).reduce(
    (acc, resourceType) => ({
      ...acc,
      [resourceType]: getAdminPermissionsOnEmployee({
        userRoles,
        employee,
        resourceType: resourceType as ResourceType,
      }),
    }),
    {} as AllResourcePermissions,
  );
};
