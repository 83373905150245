import { useMemo } from 'react';

import { Employee } from '../../common/types';
import { getAllPermissionsOnEmployee } from './getAllPermissionsOnEmployee';
import { RoleType } from './types';

export const useAllEmployeePermissions = ({
  userRoles,
  employee,
}: {
  userRoles: RoleType[];
  employee: Employee;
}) => {
  return useMemo(
    () =>
      getAllPermissionsOnEmployee({
        userRoles,
        employee,
      }),
    [userRoles, employee.managerId],
  );
};
