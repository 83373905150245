import { BasePermissions, EmployeeType, RoleType } from '../types';

export interface ExpensePermissions extends BasePermissions {
  canAccessList: boolean;
  canSeeAmount: boolean;
  canDownloadProof: boolean;
  canDelete: boolean;
}

export const expensePermissionsMatrix: Record<
  EmployeeType,
  Record<RoleType, ExpensePermissions>
> = {
  internal: {
    admin: {
      canAccessList: false,
      canSeeAmount: false,
      canDownloadProof: false,
      canDelete: false,
    },
    'viewer:clients-sensitive-data': {
      canAccessList: false,
      canSeeAmount: false,
      canDownloadProof: false,
      canDelete: false,
    },
    'viewer:omnipresent-sensitive-data': {
      canAccessList: true,
      canSeeAmount: true,
      canDownloadProof: true,
      canDelete: true,
    },
    payroll: {
      canAccessList: true,
      canSeeAmount: true,
      canDownloadProof: true,
      canDelete: false,
    },
    finance: {
      canAccessList: true,
      canSeeAmount: true,
      canDownloadProof: true,
      canDelete: false,
    },
    'international-expansion': {
      canAccessList: false,
      canSeeAmount: false,
      canDownloadProof: false,
      canDelete: false,
    },
    'benefits-specialist': {
      canAccessList: false,
      canSeeAmount: false,
      canDownloadProof: false,
      canDelete: false,
    },
  },
  client: {
    admin: {
      canAccessList: true,
      canSeeAmount: false,
      canDownloadProof: false,
      canDelete: false,
    },
    'viewer:clients-sensitive-data': {
      canAccessList: true,
      canSeeAmount: true,
      canDownloadProof: true,
      canDelete: true,
    },
    'viewer:omnipresent-sensitive-data': {
      canAccessList: false,
      canSeeAmount: false,
      canDownloadProof: false,
      canDelete: false,
    },
    payroll: {
      canAccessList: true,
      canSeeAmount: true,
      canDownloadProof: true,
      canDelete: false,
    },
    finance: {
      canAccessList: true,
      canSeeAmount: true,
      canDownloadProof: true,
      canDelete: false,
    },
    'international-expansion': {
      canAccessList: false,
      canSeeAmount: false,
      canDownloadProof: false,
      canDelete: false,
    },
    'benefits-specialist': {
      canAccessList: false,
      canSeeAmount: false,
      canDownloadProof: false,
      canDelete: false,
    },
  },
};
